import { memo } from 'react';
import { motion } from 'framer-motion';
import cls from './LoaderPage.module.scss';

export const LoaderPage = memo(() => (
  <div className={cls.LoaderPage}>
    <motion.svg
      width="100"
      height="200"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_25_11823)">
        <g>
          <motion.path
          // eslint-disable-next-line max-len
            d="M23.4076 24.3374L7.48833 8.01466C5.88867 6.37446 3.29611 6.37344 1.69768 8.01238C0.0992534 9.65132 0.100249 12.3096 1.69991 13.9498L17.6192 30.2726C19.2188 31.9128 21.8114 31.9138 23.4098 30.2748C25.0083 28.6359 25.0073 25.9776 23.4076 24.3374Z"
            fill="#d02e26"
            animate={{
              scale: [0, 0.5, 1, 1, 0],
              opacity: [0.3, 1, 1, 0],
            }}
            transition={{
              duration: 1.3,
              ease: 'linear',
              times: [0.2, 0.7, 0.8, 1.3],
              repeat: Infinity,
            }}
          />
          <motion.path
          // eslint-disable-next-line max-len
            d="M22.3024 12.9023L10.8679 1.17802C10.175 0.467516 9.05149 0.467516 8.35855 1.17802C7.66562 1.88852 7.66562 3.04047 8.35856 3.75097L19.793 15.4753C20.486 16.1858 21.6094 16.1858 22.3024 15.4753C22.9953 14.7648 22.9953 13.6128 22.3024 12.9023Z"
            fill="#d02e26"
            animate={{
              scale: [0.2, 1, 0.2],
              opacity: [0, 1, 0],
            }}
            transition={{
              duration: 1.3,
              ease: 'linear',
              times: [0.4, 0.7, 1.1],
              repeat: Infinity,
            }}
          />
          <motion.path
          // eslint-disable-next-line max-len
            d="M16.7994 34.4736L5.36495 22.7493C4.67201 22.0388 3.54853 22.0388 2.85559 22.7493C2.16265 23.4598 2.16265 24.6118 2.85559 25.3223L14.2901 37.0466C14.983 37.7571 16.1065 37.7571 16.7994 37.0466C17.4924 36.3361 17.4924 35.1841 16.7994 34.4736Z"
            fill="#d02e26"
            animate={{
              scale: [0.2, 1, 0.2],
              opacity: [0, 1, 0],
            }}
            transition={{
              duration: 1.3,
              ease: 'linear',
              times: [0.3, 0.7, 1.1],
              repeat: Infinity,
            }}
          />
        </g>
      </g>
    </motion.svg>
  </div>

));
