import { Mods, classNames } from 'shared/lib/classNames';
import { Link } from 'react-router-dom';
import { useMemo } from 'react';
import { Variants, motion } from 'framer-motion';
import cls from './MenuItem.module.scss';

interface MenuItemProps {
  className?: string;
  to: string;
  title?: string;
  setRef: (el: HTMLLIElement | null) => void;
  active: boolean;
  onClick: () => void;
  variants?: Variants;
  external: boolean;
}

export const MenuItem = (props : MenuItemProps) => {
  const {
    className,
    setRef,
    to,
    title,
    active,
    onClick,
    variants,
    external,
  } = props;

  const linkMods:Mods = useMemo(() => (
    { [cls.menuLinkActive]: active }
  ), [active]);

  return (
    <motion.li
      ref={(el) => setRef(el)}
      variants={variants}
      className={classNames(cls.MenuItem, linkMods, [className])}
    >
      <div className={classNames(cls.menuDot, {}, [])} />
      <Link
        to={to}
        target={external ? '_blank' : undefined}
        className={cls.menuLink}
        onClick={onClick}
      >
        {title}
      </Link>
    </motion.li>
  );
};
