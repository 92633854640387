import { Suspense, memo, useCallback } from 'react';
import { Route, Routes, RouteProps } from 'react-router-dom';
import { routeConfig } from 'shared/configs/routes/routeConfig';
import { useDocumentTitle } from 'shared/lib/hooks/useDocumentTitle';
import { LoaderPage } from 'shared/ui/LoaderPage';
import { ScrollToTop } from './ScrollToTop';

const AppRouter = () => {
  useDocumentTitle();

  const renderWithWrapper = useCallback((route: RouteProps) => (
    <Route
      key={route.path}
      element={route.element}
      path={route.path}
    />
  ), []);

  return (
    <Suspense fallback={<LoaderPage />}>
      <ScrollToTop>
        <Routes>
          {Object.values(routeConfig).map(renderWithWrapper)}
        </Routes>
      </ScrollToTop>
    </Suspense>

  );
};

export default memo(AppRouter);
