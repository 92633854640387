import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useLocation } from 'react-router-dom';

export function useMenu(
  menuItems:{
  path: string;
  title: string;
}[],
) {
  const { pathname } = useLocation();

  const [activeTabIndex, setActiveTabIndex] = useState<number | undefined>(undefined);
  const [indicatorStyles, setIndicatorStyles] = useState<{
    left: number,
    width: number,
    height: number
  } | undefined>({
    left: 0,
    width: 0,
    height: 0,
  });

  const [isOpen, setIsOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const indicatorTimer = useRef<ReturnType<typeof setTimeout>>();

  const menuToggle = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);
  const linkToggle = useCallback(() => {
    if (isOpen) {
      setIsOpen(false);
    }
  }, [isOpen]);

  const tabsRef = useRef<{
    offsetLeft: number;
    clientWidth: number;
    clientHeight: number;
    offsetTop: number;
  }[]>([]);

  const listenerToResize = useCallback(() => {
    clearTimeout(indicatorTimer.current);

    indicatorTimer.current = setTimeout(() => {
      if (activeTabIndex !== undefined) {
        const currentTab = tabsRef?.current[activeTabIndex];
        setIndicatorStyles({
          width: currentTab?.clientWidth ?? 0,
          height: currentTab?.clientHeight ?? 0,
          left: currentTab?.offsetLeft ?? 0,
        });
      }
    }, 300);
  }, [activeTabIndex]);

  // indicator positions
  useEffect(() => {
    listenerToResize();
    window.addEventListener('resize', listenerToResize);

    return () => {
      window.removeEventListener('resize', listenerToResize);
      clearTimeout(indicatorTimer.current);
    };
  }, [listenerToResize]);

  // active menu item
  useEffect(() => {
    let timer:ReturnType<typeof setTimeout>;

    if (menuItems !== undefined) {
      const index = menuItems.findIndex((tab) => tab.path === pathname);
      if (index !== -1) {
        setActiveTabIndex(index);
      } else {
        setActiveTabIndex(undefined);
        timer = setTimeout(() => {
          setIndicatorStyles(undefined);
        }, 500);
      }
    }

    return () => {
      clearTimeout(timer);
    };
  }, [pathname, menuItems]);

  // window width
  useEffect(() => {
    const setWindowDimensions = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', setWindowDimensions);
    return () => {
      window.removeEventListener('resize', setWindowDimensions);
    };
  }, []);

  const setRef = useCallback((el: HTMLLIElement | null, index: number) => {
    tabsRef.current[index] = el!;
  }, []);

  return {
    setRef,
    activeTabIndex,
    indicatorStyles,
    isOpen,
    menuToggle,
    linkToggle,
    windowWidth,
  };
}
