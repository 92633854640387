import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import Logo from 'shared/assets/icons/media/shift.svg';
import { classNames } from 'shared/lib/classNames';
import { headerVar } from 'shared/configs/animations/layouts';
import { Menu } from '../Menu/Menu';
import cls from './Header.module.scss';

interface HeaderProps { className?: string }

export const Header = (props : HeaderProps) => {
  const { className } = props;

  return (
    <motion.header
      variants={headerVar}
      initial="hidden"
      animate="visible"
      className={classNames(cls.Header, {}, [className])}
    >
      <Link to="/" className={cls.headerLogo}>
        <Logo />
      </Link>
      <Menu />
    </motion.header>
  );
};
