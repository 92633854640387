import { motion } from 'framer-motion';
import { classNames } from 'shared/lib/classNames';
import {
  backdropVar, containerVar, menuItemVar, navigationVar, navMenuVar,
} from '../../configs/animations';
import { useMenu } from '../../hooks/useMenu';
import { useGetNavigateRoutes } from '../../hooks/useGetNavigateRoutes';
import { MenuItem } from '../MenuItem/MenuItem';
import { MenuButton } from '../MenuButton/MenuButton';
import cls from './Menu.module.scss';

const COMPACT_MENU_SIZE = 750;

interface MenuProps {
  className?: string;
}

export const Menu = (props : MenuProps) => {
  const { className } = props;

  const menuItems = useGetNavigateRoutes();
  const {
    isOpen,
    setRef,
    activeTabIndex,
    menuToggle,
    linkToggle,
    indicatorStyles,
    windowWidth,
  } = useMenu(menuItems);

  if (windowWidth <= COMPACT_MENU_SIZE) {
    return (
      <>
        <motion.nav
          className={classNames(
            cls.Menu,
            { [cls.isOpen]: isOpen },
            [className],
          )}
          initial={false}
          variants={navMenuVar}
          animate={isOpen ? 'open' : 'closed'}
        >
          <motion.div
            variants={containerVar}
            className={cls.container}
          >
            <motion.ul
              variants={navigationVar}
              className={cls.navigation}
            >
              {
                menuItems?.map((item, index) => (
                  <MenuItem
                    key={item.path}
                    setRef={(el) => setRef(el, index)}
                    to={item.path}
                    title={item.title}
                    external={item.external}
                    active={activeTabIndex === index}
                    onClick={linkToggle}
                    variants={menuItemVar}
                  />
                ))
              }
            </motion.ul>
          </motion.div>
          <motion.div
            variants={backdropVar}
            className={cls.backdrop}
          />
        </motion.nav>
        <MenuButton
          isOpen={isOpen}
          onClick={menuToggle}
          className={cls.menuBtn}
        />
      </>
    );
  }

  return (
    <nav
      className={classNames(
        cls.Menu,
        {},
        [className],
      )}
    >
      <ul className={cls.navigation}>
        {
          menuItems?.map((item, index) => (
            <MenuItem
              key={item.path}
              external={item.external}
              setRef={(el) => setRef(el, index)}
              to={item.path}
              title={item.title}
              active={activeTabIndex === index}
              onClick={linkToggle}
            />
          ))
        }
        <div
          style={indicatorStyles}
          className={
            classNames(
              cls.navigationIndicator,
              { [cls.indicatorHide]: activeTabIndex === undefined },
              [],
            )
          }
        />
      </ul>
    </nav>
  );
};
