/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { classNames } from 'shared/lib/classNames';
import cls from './MenuButton.module.scss';

interface MenuButtonProps {
  className?: string;
  onClick: () => void;
  isOpen: boolean;
}

export const MenuButton = (props : MenuButtonProps) => {
  const { className, onClick, isOpen } = props;

  return (
    <button
      className={classNames(cls.MenuButton, {}, [className])}
      type="button"
      onClick={onClick}
    >
      <input
        id="checkbox"
        className={cls.checkbox}
        type="checkbox"
        checked={isOpen}
        onChange={onClick}
      />
      <label
        className={cls.toggle}
        htmlFor="checkbox"
      >
        <div className={classNames(cls.bars, {}, [cls.bar1])} />
        <div className={classNames(cls.bars, {}, [cls.bar2])} />
        <div className={classNames(cls.bars, {}, [cls.bar3])} />
      </label>
    </button>
  );
};
