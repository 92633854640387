import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { routeConfig } from 'shared/configs/routes/routeConfig';

export function useDocumentTitle() {
  const location = useLocation();

  useEffect(() => {
    const route = Object
      .values(routeConfig)
      .find((item) => item.path.split('/')[1] === location.pathname.split('/')[1]);

    if (route?.title) {
      document.title = `${__APP_TITLE__} | ${route.title}`;
    }
  }, [location]);
}
