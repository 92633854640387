import { memo } from 'react';
import { LoaderPage } from 'shared/ui/LoaderPage';
import cls from './SwapDynamicLoadErrorPage.module.scss';

export const SwapDynamicLoadErrorPage = memo(() => (
  <div className="app">
    <div
      className={cls.SwapDynamicLoadErrorPage}
    >
      <div className={cls.content}>
        <h3>New update available.</h3>
        <h4>Reloading in progress. Thanks for your patience.</h4>
        <LoaderPage />
      </div>
    </div>
  </div>
));
