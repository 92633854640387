import { memo } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import LogoIcon from 'shared/assets/icons/media/shift.svg';
import XIcon from 'shared/assets/icons/media/x.svg';
import MediumIcon from 'shared/assets/icons/media/medium.svg';
import DiscordIcon from 'shared/assets/icons/media/discord.svg';
import LinkedInIcon from 'shared/assets/icons/media/linkedin.svg';
import { footerVar } from 'shared/configs/animations/layouts';
import { ExternalResources } from 'shared/configs/routes/externalResources';
import { routeConfig } from 'shared/configs/routes/routeConfig';
import cls from './Footer.module.scss';

export const CircleSeparator = memo(() => (<div className={cls.CircleSeparator} />));

export const Footer = memo(
  () => (
    <motion.div
      variants={footerVar}
      initial="hidden"
      animate="visible"
      className={cls.Footer}
    >
      <div className={cls.columns}>
        <div className={cls.mainInfo}>
          <LogoIcon />
          <span>
            SHIFT is a digital asset and blockchain technology focused company that
            provides wide range of products &mdash; from asset management in digital assets
            to IT and risk management solutions for companies investing and operating on chain.
          </span>
          <a href="mailto:info@shiftam.com">info@shiftam.com</a>
        </div>
        <div className={cls.textLinks}>
          <Link to={routeConfig.products.path}>
            {routeConfig.products.title}
          </Link>
          <CircleSeparator />
          <Link to={routeConfig.team_and_vision.path}>
            {routeConfig.team_and_vision.title}
          </Link>
          <CircleSeparator />
          <Link to={routeConfig.our_approach.path}>
            {routeConfig.our_approach.title}
          </Link>
          <CircleSeparator />
          <Link to={routeConfig.disclaimer.path}>
            {routeConfig.disclaimer.title}
          </Link>
        </div>
        <div className={cls.communityContainer}>
          <a
            target="_about"
            aria-label="x"
            href={ExternalResources.media.x}
          >
            <XIcon />
          </a>
          <a
            target="_about"
            aria-label="medium"
            href={ExternalResources.media.medium}
          >
            <MediumIcon />
          </a>
          <a
            target="_about"
            aria-label="discord"
            href={ExternalResources.media.discord}
          >
            <DiscordIcon />
          </a>
          <a
            target="_about"
            aria-label="linkedin"
            href={ExternalResources.media.linkedin}
          >
            <LinkedInIcon />
          </a>
        </div>
      </div>
      <div className={cls.disclaimer}>
        <span>
          The content of this website does not represent investment advice and is for informational
          purposes only. Such information is not intended as an offer to sell, or the solicitation of
          an offer to buy any securities or digital assets.
        </span>
        <p>
          ©2024 SHIFT All Rights Reserved
        </p>
      </div>
    </motion.div>
  ),
);
