import { Suspense } from 'react';
import { Header } from 'widgets/Header';
import { Footer } from 'widgets/Footer';
import { AppRouter } from './providers/RouterProvider';

const App = () => (
  <div className="app">
    <Suspense fallback="">
      <Header />
      <div className="content-page">
        <AppRouter />
      </div>
      <Footer />
    </Suspense>
  </div>
);
export default App;
