import { RouteProps } from 'react-router-dom';
import { HomePage } from 'pages/HomePage';
import { ProducePage } from 'pages/ProductPage';
import { OurApproachPage } from 'pages/OurApproachPage';
import { TeamAndVisionPage } from 'pages/TeamAndVisionPage';
import { NotFoundPage } from 'pages/NotFoundPage';
import { DisclaimerPage } from 'pages/DisclaimerPage';

export type AppRoutesProps = RouteProps & {
  title: string;
  path: string;
  navigate?: boolean;
}

export enum AppRoutes {
  DISCLAIMER = 'disclaimer',
  HOME = 'home',
  NOT_FOUND = 'not_found',
  OUR_APPROACH = 'our_approach',
  PRODUCTS = 'products',
  TEAM_AND_VISION = 'team_and_vision'
}

export const RoutePath: Record<AppRoutes, string> = {
  [AppRoutes.HOME]: '/',
  [AppRoutes.PRODUCTS]: `/${AppRoutes.PRODUCTS}`,
  [AppRoutes.TEAM_AND_VISION]: `/${AppRoutes.TEAM_AND_VISION}`,
  [AppRoutes.OUR_APPROACH]: `/${AppRoutes.OUR_APPROACH}`,
  [AppRoutes.DISCLAIMER]: `/${AppRoutes.DISCLAIMER}`,
  [AppRoutes.NOT_FOUND]: '*',
};

export const routeConfig: Record<AppRoutes, AppRoutesProps> = {
  [AppRoutes.HOME]: {
    title: '',
    path: RoutePath.home,
    element: <HomePage />,
  },
  [AppRoutes.PRODUCTS]: {
    title: 'Products',
    path: RoutePath.products,
    element: <ProducePage />,
    navigate: true,
  },
  [AppRoutes.OUR_APPROACH]: {
    title: 'Our approach',
    path: RoutePath.our_approach,
    element: <OurApproachPage />,
    navigate: true,
  },
  [AppRoutes.TEAM_AND_VISION]: {
    title: 'Team and vision',
    path: RoutePath.team_and_vision,
    element: <TeamAndVisionPage />,
    navigate: true,
  },
  [AppRoutes.DISCLAIMER]: {
    title: 'Disclaimer',
    path: RoutePath.disclaimer,
    element: <DisclaimerPage />,
  },
  [AppRoutes.NOT_FOUND]: {
    title: 'Not found page',
    path: RoutePath.not_found,
    element: <NotFoundPage />,
  },
};
