import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from 'app/App';
import { ThemeProvider } from 'app/providers/ThemeProvider';
import { ErrorBoundary } from 'app/providers/ErrorBoundary';
import { SwapDynamicLoadErrorPage } from 'pages/SwapDynamicLoadErrorPage';
import 'app/styles/index.scss';

const container = document.getElementById('root');

if (!container) {
  throw new Error('Root container is undefined!');
}

const root = createRoot(container);

if (!__IS_DEV__) {
  window.addEventListener('vite:preloadError', () => {
    window.location.reload();
    root.render(
      <ThemeProvider>
        <SwapDynamicLoadErrorPage />
      </ThemeProvider>,
    );
  });
}

root.render(
  <ErrorBoundary>
    <Router>
      <ThemeProvider>
        <App />
      </ThemeProvider>
    </Router>
  </ErrorBoundary>,
);
