import { useMemo } from 'react';
import { AppRoutes, routeConfig } from 'shared/configs/routes/routeConfig';

export function useGetNavigateRoutes() {
  const menuItems = useMemo(() => {
    const navigateRoutes = Object.keys(routeConfig)
      .reduce((acc, key) => {
        if (routeConfig[key as AppRoutes].navigate) {
          return {
            ...acc,
            [key]: { ...routeConfig[key as AppRoutes] },
          };
        }
        return { ...acc };
      }, {});

    return Object.keys(navigateRoutes).map((key) => (
      {
        path: routeConfig[key as AppRoutes]?.path,
        title: routeConfig[key as AppRoutes]?.title,
        external: routeConfig[key as AppRoutes]?.element === undefined,
      }
    ));
  }, []);

  return menuItems;
}
