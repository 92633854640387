export const navMenuVar = {
  open: {
    top: 0,
    height: '100vh',
    transition: {
      delay: 0,
      duration: 0.5,
      type: 'tween',
    },
  },
  closed: {
    top: '-100vh',
    height: 0,
    transition: {
      delay: 0.4,
      duration: 0.5,
      type: 'tween',
    },
  },
};

export const menuItemVar = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: {
        stiffness: 1000,
        velocity: -100,
      },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    duration: 0.2,
    transition: {
      y: {
        stiffness: 1000,
      },
    },
  },
};

export const containerVar = {
  open: {
    transition: {
      delayChildren: 0.8,
      staggerChildren: 0.1,
    },
  },
  closed: {
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -100,
    },
  },
};

export const navigationVar = {
  open: {
    transition: {
      delayChildren: -0.3,
      staggerChildren: 0.2,
    },
  },
  closed: {
    transition: {
      delayChildren: 0,
      staggerChildren: 0.1,
      staggerDirection: -100,
    },
  },
};

export const backdropVar = {
  open: {
    opacity: 1,
    display: 'block',
    transition: {
      delay: 0.2,
      duration: 1,
    },
  },
  closed: {
    opacity: 0,
    transition: {
      delay: 0,
      duration: 1,
    },
    transitionEnd: {
      display: 'none',
    },
  },
};
