import { Variants } from 'framer-motion';

const defaultVar:Variants = {
  hidden: {
    y: 0,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
      delay: 0.2,
    },
  },
};

export const headerVar:Variants = {
  ...defaultVar,
  visible: {
    ...defaultVar.visible,
    transition: {
      delay: 0.3,
      duration: 0.5,
    },
  },
  hidden: {
    ...defaultVar.hidden,
    y: -50,
  },
};
export const footerVar:Variants = {
  ...defaultVar,
  visible: {
    ...defaultVar.visible,
    transition: {
      delay: 0.3,
      duration: 0.5,
    },
  },
  hidden: {
    ...defaultVar.hidden,
    y: 50,
  },
};

export const blockVarContainer:Variants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.3,
    },
  },
};

export const blockVar:Variants = {
  hidden: {
    y: 30,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.4,
      delay: 0.3,
    },
  },
};
